<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent">
      <article v-if="entity"
        class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              <span>Details</span><span v-show="entity">{{ ` - ${entity.prefix}${entity.quoteNo}${entity.suffix}` }}</span>
            </p>
            <div v-if="entity.orm">
              <div class="field">
                <label for=""
                  class="label">Last Message No</label>
                <vue-numeric class="input"
                  v-model="entity.orm.messageNo"
                  :precision="0" />
              </div>
              <div class="field">
                <label for=""
                  class="label">Quote Status</label>
                <div class="select">
                  <select v-model="entity.orm.quoteStatusId">
                    <option v-for="type in quoteStatusTypes"
                      :key="type.value"
                      :value="type.value">{{ type.name }}</option>
                  </select>
                </div>
              </div>
              <div class="is-divider"
                data-content="" />
              <div class="tabs">
                <ul>
                  <li :class="{ 'is-active' : subComponent === 'OrmQuoteItems' }">
                    <a @click="subComponent = 'OrmQuoteItems'">Items</a>
                  </li>
                  <li :class="{ 'is-active' : subComponent === 'OrmQuoteImages' }">
                    <a @click="subComponent = 'OrmQuoteImages'">Images</a>
                  </li>
                  <li :class="{ 'is-active' : subComponent === 'OrmQuoteRemarks' }">
                    <a @click="subComponent = 'OrmQuoteRemarks'">Remarks</a>
                  </li>
                </ul>
              </div>
              <component :is="subComponent"
                v-model="entity" />
            </div>
            <div v-else
              class="subtitle has-text-danger">
              No ORM/PNET record
            </div>
          </div>
        </div>
      </article>
      <article v-else
        class="tile is-child box detail-page-tile">
        <div>
          <p class="title">
            Detail
          </p>
          <p class="subtitle">Please select a quote</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { QuoteService, OrmService } from '@/services'
import VueNumeric from '@/components/VueNumeric'
import { OrmQuoteItems, OrmQuoteImages, OrmQuoteRemarks } from './components'

export default {
  name: 'OrmUtilitiesDetail',
  components: {
    VueNumeric,
    OrmQuoteItems,
    OrmQuoteImages,
    OrmQuoteRemarks
  },
  mixins: [],
  props: {
    // quoteId: ''
  },
  data: () => {
    return {
      entity: null,
      quoteStatusTypes: null,
      subComponent: 'OrmQuoteItems',
      isLoadingImages: false,
      imagesWithInfo: [],
      imageIds: [],
      size: 200
    }
  },
  computed: {
    // quoteId() {
    //   return this.entity ? this.entity.quoteId : ''
    // }
  },
  watch: {
    '$route.params.quoteId': function (newVal, oldVal) {
      this.getEntity()
    }
  },
  created() {
    this.getEntity()
    this.getOrmQuoteStatusTypes()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getEntity() {
      if (this.$route.params.quoteId && this.$guid.validGuid(this.$route.params.quoteId)) {
        this.entity = await QuoteService.getEntity(this.$route.params.quoteId)
        this.getThumbnails()
      }
    },
    async getOrmQuoteStatusTypes() {
      this.quoteStatusTypes = await OrmService.getOrmQuoteStatusTypes()
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.entity.quoteId)
      const promises = this.imageIds.map(function (id, index) {
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.isLoadingImages = false
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>