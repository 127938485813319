<template>
  <div v-if="$userInfo.isSupportUser">
    <app-detail-header :show-spinner="false"
      ref="sectionHeader" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <pnet-utilities-side-menu ref="sideMenu"
          :key="$route.params.quoteId"
          @select-quote="selectQuote" />
      </div>
      <div class="column">
        <router-view ref="currentChild" />
      </div>
    </div>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import PnetUtilitiesSideMenu from './PnetUtilitiesSideMenu'
import UtilitiesRoutes from '../route-types'

export default {
  name: 'PnetUtilitiesView',
  components: {
    AppDetailHeader,
    PnetUtilitiesSideMenu
  },
  mixins: [],
  props: {
    // quoteId: ''
  },
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    selectQuote(id) {
      this.$router.replace({
        name: UtilitiesRoutes.PnetUtilitiesDetail.name,
        params: { quoteId: id }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>
