<template>
  <div>
    Quote Items
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'OrmQuoteItems',
  components: {},
  mixins: [],
  props: {
    value: null
  },
  data: () => {
    return {
      innerValue: null
    }
  },
  computed: {},
  watch: {},
  created() {
    this.innerValue = _cloneDeep(this.value)
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>