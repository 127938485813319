<template>
  <div>
    Orm Quote Images
  </div>
</template>

<script>
export default {
  name: 'OrmQuoteimages',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>