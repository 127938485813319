<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <ul class="menu-list">
        <li>
          <bulma-autocomplete :value="quoteNo"
            :data="quotes"
            field="quoteNoRef"
            :keep-first="true"
            :loading="isSearchingQuote"
            @input="searchQuote"
            @select="selectQuote"
            :expanded="true"
            placeholder="Quote No."
            class="is-uppercase">
            <template slot-scope="props">
              <div class="columns">
                <div class="column">
                  <span>{{ props.option.quoteNoRef }}</span>
                </div>
                <div class="column is-flex is-justify-content-flex-end">
                  <div class="tags">
                    <span v-if="props.option.hasOrm"
                      class="tag is-primary">
                      ORM
                    </span>
                    <span v-if="props.option.hasPnet"
                      class="tag is-primary">
                      PNET
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </bulma-autocomplete>
        </li>
      </ul>
      <hr>
      <!-- <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Retrieve</span>
          </button>
        </div>
      </div> -->
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import UtilitiesRoutes from '../route-types'
import { BulmaAutocomplete } from '@/components/BulmaAutocomplete'
import { QuoteService } from '@/services'
import Guid from '@/components/Guid'

export default {
  name: 'PnetUtilitiesSideMenu',
  components: {
    AppSideMenu,
    BulmaAutocomplete
  },
  mixins: [],
  props: {},
  data: () => {
    return {
      quotes: [],
      quoteNo: '',
      isSearchingQuote: false,
      filter: {
        quoteNoRef: '',
        regoNo: '',
        customerName: '',
        claimNo: '',
        dateFrom: '',
        dateTo: '',
        insurerId: Guid.empty(),
        insurerName: '',
        jobStage: 0,
        active: 1,
        invoiceStatus: 'B',
        authorisedStatus: 'B',
        make: '',
        model: '',
        quoteType: 'Q',
        sortColumn: 'quoteNoRef',
        sortOrder: 'desc',
        pageIndex: 1,
        pageSize: 12
      }
    }
  },
  computed: {
    routeTypes() {
      return UtilitiesRoutes
    }
  },
  watch: {},
  created() {
    this.getQuotes()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getQuotes() {
      this.quotes = await QuoteService.getEntitySummaries(this.filter)
    },
    searchQuote(value) {
      this.filter.quoteNoRef = value
      this.getQuotes()
    },
    selectQuote(value) {
      this.$route.params.quoteId = value.quoteId
      this.$emit('select-quote', this.$route.params.quoteId)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>