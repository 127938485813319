<template>
  <div>
    <div v-if="entity"
      class="tile is-ancestor is-parent">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <p class="title">PNET Details</p>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <input class="is-checkradio is-info"
                  v-model="readOnly"
                  type="checkbox"
                  id="readOnly">
                <label for="readOnly">Read Only</label>
              </div>
              <div class="field">
                <input class="is-checkradio is-info"
                  v-model="entity.pnet.extraVehicleInfo"
                  type="checkbox"
                  id="extraVehicleInfo">
                <label for="part">Extra Vehicle Info</label>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">PNET Qr No</label>
                <div class="control quote-width">
                  <input class="input is-uppercase"
                    v-model="entity.pnet.pnetQuoteNo"
                    type="text">
                </div>
              </div>

              <div class="field">
                <label class="label">Qr Ver</label>
                <div class="control quote-width">
                  <input class="input is-uppercase"
                    v-model="entity.pnet.quoteRequestVersion"
                    type="text">
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">PNET Claim No</label>
                <div class="control">
                  <input class="input is-uppercase"
                    v-model="entity.pnet.claimNo"
                    type="text">
                </div>
              </div>
              <div class="field">
                <label class="label">PNET Quote No</label>
                <div class="control">
                  <input class="input is-uppercase"
                    v-model="entity.pnet.pnetQuoteNo"
                    type="text">
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div v-if="entity"
      class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Set Quote Status</p>
      </article>
    </div>
    <div v-if="entity"
      class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Image Sent</p>
        <div class="is-flex is-justify-content-right pt-3">
          <button class="button is-primary"
            @click="clearSent">Clear Sent</button>
        </div>
      </article>
    </div>
    <div v-else
      class="tile is-ancestor is-parent">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <div>
            <p class="title">
              Detail
            </p>
            <p class="subtitle">Please select a quote</p>
          </div>
        </article>
      </div>
    </div>
  </div></template>

<script>
import { QuoteService, PnetService } from '@/services'

export default {
  name: 'PnetUtilitiesDetail',
  components: {},
  mixins: [],
  props: {
    // quoteId: ''
  },
  data: () => {
    return {
      entity: null,
      quoteStatusTypes: null,
      // subComponent: 'OrmQuoteItems',
      readOnly: false,
      isLoadingImages: false,
      imagesWithInfo: [],
      imageIds: [],
      size: 200
    }
  },
  computed: {
    // quoteId() {
    //   return this.entity ? this.entity.quoteId : ''
    // }
  },
  watch: {
    '$route.params.quoteId': function (newVal, oldVal) {
      this.getEntity()
    }
  },
  created() {
    this.getEntity()
    // this.getPnetQuoteStatusTypes()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getEntity() {
      if (this.$route.params.quoteId && this.$guid.validGuid(this.$route.params.quoteId)) {
        this.entity = await QuoteService.getEntity(this.$route.params.quoteId)
        this.getThumbnails()
      }
    },
    async getOrmQuoteStatusTypes() {
      this.quoteStatusTypes = await PnetService.getPnetQuoteStatusTypes()
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.entity.quoteId)
      const promises = this.imageIds.map(function (id, index) {
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.isLoadingImages = false
    },
    clearSent() {}
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.quote-width {
  width: 150px;
}
</style>
